import React, { useState } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { BoldText, ButtonContainer, Heading, JsonText, Paragraph } from "./Styles"
import OnClickButtonOrangeDark from "../Core/Buttons/OnClickButtonOrangeDark"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import ChevronLeft from "../../resources/img/ui/dark/chevron-left.svg"
import { isBrowser } from "../../services/browser"
import { lambdaCall } from "../../services/lambdaCall"

const ContentWrapper = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    max-width: 520px;
  }
  @media (min-width: ${breakpoints.max}) {
    max-width: unset;
  }
`

const SlideInfo = styled(BoldText)`
  margin-bottom: 119px;
  @media (min-width: ${breakpoints.lg}) {
    text-align: left;
    margin: 26px 0 96px;
  }
`

const Title = styled(Heading)`
  margin-bottom: 21px;
  @media (min-width: ${breakpoints.lg}) {
    text-align: left;
    margin-bottom: 25px;
  }
`
const SubTitle = styled(Paragraph)`
  line-height: 1.6;
  text-align: center;
  margin-bottom: 30px;
  padding: 0 15px;
  @media (min-width: ${breakpoints.lg}) {
    text-align: left;
    margin-bottom: 28px;
    padding: 0;
  }
`

const Content = styled(JsonText)`
  p {
    line-height: 1.4;
    text-align: center;
    @media (min-width: ${breakpoints.lg}) {
      text-align: left;
    }
  }
`

const ContactButtonContainer = styled(ButtonContainer)`
  height: 200px;
  @media (min-width: ${breakpoints.lg}) {
    position: static;
    display: block;
    width: 100%;
    max-width: 520px;
    padding: 13px 0 0;
  }
  @media (min-width: ${breakpoints.max}) {
    max-width: unset;
  }
`
const ButtonWrapper = styled.div`
  margin-bottom: 13px;
  button {
    width: 100%;
  }
`

const StyledInput = styled.input`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 16px;
  line-height: 1.8;
  font-weight: 300;
  background: transparent;
  outline: none;
  border: none !important;
  width: 100%;
  border-bottom: 1px solid ${colors.grey} !important;
  margin-top: 30px;
  padding-bottom: 13px;
  -webkit-appearance: none;

  ::-webkit-input-placeholder {
    ${fonts.labGrotesqueLight};
    color: ${colors.grey};
  }
  ::-moz-placeholder {
    ${fonts.labGrotesqueLight};
    color: ${colors.grey};
  }
  :-ms-input-placeholder {
    ${fonts.labGrotesqueLight};
    color: ${colors.grey};
  }
  :-moz-placeholder {
    ${fonts.labGrotesqueLight};
    color: ${colors.grey};
  }
`

const Information = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 16px;
  line-height: 25px;
  font-weight: 300;
  width: 100%;
`

const QuizContact = ({
  slide,
  decreaseSlideIndex,
  userAnswerList,
  results,
}) => {
  const { heading, title, subtitle, content, ctaLabel } = slide
  const [email, setEmail] = useState("")
  const [submitted, setSubmitted] = useState(false)

  const getResultUrl = () => {
    const resultCounts = {}

    userAnswerList.forEach(({ result }) => {
      if (result) {
        resultCounts[result] = (resultCounts[result] || 0) + 1
      }
    })

    const selectedResult = Object.keys(resultCounts).reduce((a, b) =>
      resultCounts[a] > resultCounts[b] ? a : b
    )

    return selectedResult
  }

  const getUserData = () => {
    const answers = [...userAnswerList]
    const userData = {
      email: email,
    }

    answers.forEach((answer) => {
      const dataKeys = Object.keys(userData)
      if (!dataKeys.includes(answer.questionLabel)) {
        userData[answer.questionLabel] = [answer.answerLabel]
      } else {
        userData[answer.questionLabel] = [
          ...userData[answer.questionLabel],
          answer.answerLabel,
        ]
      }
    })

    const dataKeys = Object.keys(userData)

    dataKeys.forEach((key) => {
      if (key !== "email") {
        let concatVal = userData[key].join(",")

        userData[key] = concatVal
      }
    })

    return userData
  }

  const handleResultClick = (e) => {
    e.preventDefault();
    let body = JSON.stringify(getUserData())
    const resultUrl = getResultUrl()

    lambdaCall({
      url: "quizSignup",
      method: "POST",
      body: body,
    })
      .then((response) => {
        return Promise.all([response.status, response.text()])
      })
      .then(([status]) => {
        if (status === 200) {
          setSubmitted(true)

          setTimeout(() => {
            setSubmitted(false)
          }, 3000)
        } else {
          console.log("error")
        }
      })
    if (isBrowser()) {
      window.location.href = `/quiz-result/${resultUrl}/`
    }
  }

  return (
    <>
      <ContentWrapper>
        <SlideInfo>
          <img
            src={ChevronLeft}
            alt="Left arrow"
            onClick={decreaseSlideIndex}
          />
          {heading}
        </SlideInfo>
        <Title>{title}</Title>
        {subtitle && <SubTitle>{subtitle}</SubTitle>}
        <Content>{renderRichText(content)}</Content>
        <form onSubmit={handleResultClick}>
          <StyledInput
            type="email"
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
            name={"email"}
            autoComplete={"off"}
            required
          />
          <ContactButtonContainer>
            <ButtonWrapper>
              <OnClickButtonOrangeDark type="submit">
                {ctaLabel}
              </OnClickButtonOrangeDark>
            </ButtonWrapper>
            <Information>
              By signing up you agree to receive Hummingway's email newsletter
              and special promotional offers.
            </Information>
          </ContactButtonContainer>
        </form>
      </ContentWrapper>
    </>
  )
}

export default QuizContact
