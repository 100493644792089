import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import { BoldText, ButtonContainer, Heading, JsonText, Paragraph } from "./Styles"
import OnClickButtonOrangeDark from "../Core/Buttons/OnClickButtonOrangeDark"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { generateId } from "../../services/sessionId"

const SlideInfo = styled(BoldText)`
    margin-bottom: 10px;
    margin-top: 109px;
    text-align: center;
    display: block;
    @media (min-width: ${breakpoints.lg}) {
        text-align: left;
        margin: 26px 0 46px;
    }
`

const Title = styled(Heading)`
    margin-bottom: 34px;
    @media (min-width: ${breakpoints.lg}) {
        text-align: left;
        max-width: 550px;
    }
    @media (min-width: ${breakpoints.max}) {
        max-width: unset;
    }
`
const SubTitle = styled(Paragraph)`
    line-height: 1.6;
    text-align: center;
    margin-bottom: 30px;
    padding: 0 15px;
    @media (min-width: ${breakpoints.lg}) {
        text-align: left;
        margin-bottom: 28px;
        padding: 0;
    }
`
const Content = styled(JsonText)`
    p {
        line-height: 1.4;
        text-align: center;
        @media (min-width: ${breakpoints.lg}) {
            text-align: left;
        }
    }
`

const IntroButtonContainer = styled(ButtonContainer)`
    @media (min-width: ${breakpoints.lg}) {
        position: static;
        display: block;
        padding: 35px 0 0;
    }

`
const ButtonWrapper = styled.div`
    button {
        width: 100%;
    }
    @media (min-width: ${breakpoints.lg}) {
        max-width: 258px;
    }
`

const QuizIntro = ({slide, increaseSlideIndex, setUserSessionId}) => {
    const {heading, title, subtitle, content, ctaLabel} = slide;

    const startQuiz = async () => {
        const userId = generateId()
        setUserSessionId(userId)
        increaseSlideIndex()
    }

    return (
        <>
            <div>
                <SlideInfo>{heading}</SlideInfo>
                <Title>{title}</Title>
                <SubTitle>{subtitle}</SubTitle>
                <Content>{renderRichText(content)}</Content>
            </div>
            <IntroButtonContainer>
                <ButtonWrapper>
                    <OnClickButtonOrangeDark onClick={startQuiz}>{ctaLabel}</OnClickButtonOrangeDark>
                </ButtonWrapper>
            </IntroButtonContainer>
        </>
    )
}

export default QuizIntro
