import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import BlockContainer from "../Core/Container/BlockContainer"
import SlideContents from "./SlideContents"
import { Col, Row } from "styled-bootstrap-grid"
import { GatsbyImage } from "gatsby-plugin-image"


const QuizContainer = styled.div`
    padding: 21px 29px 0;
    background: ${colors.ivory};
    
    @media (min-width: ${breakpoints.md}) {
        padding: 41px 42px 44px;
    }
    @media (min-width: ${breakpoints.lg}) {
        height: 704px;
    }
    @media (min-width: ${breakpoints.xl}) {
        height: 809px;
    }
`

const StyledImage = styled(GatsbyImage)`
    border-radius: 15px;
    display: block;
    max-width: 678px;
    max-height: 724px;
    margin-right: auto;
    @media (min-width: ${breakpoints.lg}) {
        height: 100%;
    }
    @media (min-width: ${breakpoints.xl}) {
        min-height: unset;
    }
`
const ImageCol = styled(Col)`
  display: none;
  padding-right: 50.5px;
  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }
`

const ContentCol = styled(Col)`
    min-height: calc( 100vh - 102px );
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: ${breakpoints.md}) {
      min-height: calc( 100vh - 122px );
    }
    @media (min-width: ${breakpoints.lg}) {
      min-height: unset;
      height: 100%;
      max-height: 100%;
      border-radius: 15px;
      display: block;
      max-width: 678px;
      position: relative;
      padding-left: 50.5px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  @media (min-width: ${breakpoints.lg}) {
        height: 619px;
  }
  @media (min-width: ${breakpoints.xl}) {
        height: 724px;
  }
`

const ImageText = styled.div`
  position: absolute;
  top: ${props => props.top && "-11px"};
  bottom: ${props => !props.top && "-10px"};
  left: 50%;
  transform: translateX(-50%);
  font-size: 82px;
  line-height: 1;
  ${fonts.canelaThin};
  color: ${colors.ivory};
  text-transform: uppercase;
  white-space: nowrap;
  @media (min-width: ${breakpoints.xl}) {
    font-size: 96px;
    top: ${props => props.top && "-12px"};
    bottom: ${props => !props.top && "-13px"};
  }
`

const StyledRow = styled(Row)`
  height: 100%;
  overflow: hidden;
  @media (min-width: ${breakpoints.lg}) {
    overflow: hidden;
  }
    
`

const StyledVideo = styled.video`
   border-radius: 15px;
    display: block;
    max-width: 678px;
    max-height: 724px;
    margin-right: auto;
    object-fit: cover;
    width: 100%;
    @media (min-width: ${breakpoints.lg}) {
        height: 100%;
    }
    @media (min-width: ${breakpoints.xl}) {
        min-height: unset;
    }
`

const Quiz = ( {block} ) => {
  const { media, topImageText, bottomImageText, introSlide, slides, endingSlide, quizLength } = block
  const quizSlides = [introSlide, ...slides, endingSlide]
  const questionSlides = slides.filter(slide => slide.__typename === "ContentfulQuizQuestion")
  const videoRef = useRef(null)

  useEffect(() => {
    const video = videoRef.current
    if(video) {
        video.defaultMuted = true
        video.setAttribute("muted", "1")
        video.setAttribute("playsinline", "1")
        video.setAttribute("autoplay", "1")
    }
  })

  const renderMedia = () => {
    if(media.file.contentType.includes("video")) {
      return <StyledVideo
      src={media.file.url}
      muted
      loop
      playsInline
      aria-label={media.title}
      ref={videoRef}
      type={media.file.contentType}>
        <source src={media.file.url} type={media.file.contentType}/>
            Your browser does not support the video tag.
      </StyledVideo>
    }

    return <StyledImage
      image={media.gatsbyImageData}
      alt={media.title}
    />
  }

  quizSlides.forEach((slide, index) => {
    slide.index = index
  })

  return (
    <BlockContainer>
      <QuizContainer id="quiz">
              <StyledRow>
                  <ImageCol lg={6}>
                    <ImageContainer>
                     {renderMedia()}
                      <ImageText top >{topImageText}</ImageText>
                      <ImageText>{bottomImageText}</ImageText>
                    </ImageContainer>
                  </ImageCol>
                  <ContentCol lg={6}>
                      <SlideContents slides={quizSlides} questionSlides={questionSlides} quizLength={quizLength}/>
                  </ContentCol>
              </StyledRow>
      </QuizContainer>
    </BlockContainer>
  )
}

export default Quiz
