import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { BoldText, ButtonContainer, ButtonWrapper, Heading, Paragraph } from "./Styles"
import OnClickButtonOrangeDark from "../Core/Buttons/OnClickButtonOrangeDark"
import ChevronLeft from "../../resources/img/ui/dark/chevron-left.svg"
import TextBubbleTriangle from "../../resources/img/ui/triangle.svg"
import TextBubbleTrianglePeach from "../../resources/img/ui/peach-triangle.svg"
import { ButtonStyle } from "../Core/Buttons/Styles"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { isBrowser } from "../../services/browser"
import { deleteAnswers, sendAnswers } from "../../services/quiz/quiz"

const QuestionContainer = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    max-height: 619px;
    position: relative;
    overflow-x: visible;
  }
  @media (min-width: ${breakpoints.xl}) {
    max-height: 724px;
  }
`
const Question = styled(Heading)`
  margin-bottom: 21px;
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 13px;
    max-width: 520px;
    text-align: left;
  }
`
const Direction = styled(Paragraph)`
  line-height: 1.8;
  margin-bottom: 24px;
  text-align: center;
  @media (min-width: ${breakpoints.lg}) {
    text-align: left;
  }
`
const SlideInfo = styled(BoldText)`
  margin-bottom: 119px;
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 16px;
    margin-top: 10px;
  }
`

const Top = styled.div`
  background: ${colors.ivory};
  z-index: 4;
  @media (min-width: ${breakpoints.lg}) {
    position: absolute;
    width: calc(100% + 1px);
    top: 0;
    left: -1px;
  }
`
const AnswersContainer = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    max-height: 507px;
    padding-top: ${(props) => (props.top ? `${props.top}px` : "0")};
    overflow-y: auto;
    overflow-x: visible;
    margin-left: -200px;
    padding-left: 201px;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: ${colors.peach};
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(88, 89, 91, 0.2);
    }
  }
  @media (min-width: ${breakpoints.xl}) {
    max-height: 609px;
  }
`

const ButtonAnswers = styled.div`
  @media (min-width: ${breakpoints.md}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (min-width: ${breakpoints.lg}) {
    max-width: 550px;
    justify-content: flex-start;
  }
`
const AnswerButton = styled.button`
  ${ButtonStyle};
  margin: 0 auto 15px;
  display: block;
  color: ${(props) => (props.selected ? colors.white : colors.grey)};
  background: ${(props) => (props.selected ? colors.green : "transparent")};
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: 2.4px;
  border: 1px solid ${colors.grey};
  border-color: ${(props) => (props.selected ? colors.green : colors.grey)};
  border-radius: 15px;
  padding: 20px 22.5px;
  position: relative;

  &:hover {
    border-color: ${colors.green};
    background: ${colors.green};
    color: ${colors.white};
    div {
      opacity: ${(props) => (props.selected ? "0" : "1")};
    }
  }
  @media (min-width: ${breakpoints.md}) {
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 2.4px;
    padding: 20px 22.5px;
    margin: 0 15px 15px 0;
  }
`

const RadioAnswers = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    max-width: 535px;
  }
`
const RadioButton = styled.button`
  cursor: pointer;
  padding: 17px 20px;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  background: transparent;
  display: block;
  transition: all 0.5s;
  ${fonts.labGrotesqueLight};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  color: ${colors.grey};
  padding: 0 0 0 28px;
  position: relative;
  margin-bottom: 25px;
  text-align: left;
  &:last-of-type {
    margin-bottom: 0;
  }
  &::before {
    content: "";
    position: absolute;
    top: 3px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid ${colors.grey};
    border-color: ${(props) => props.selected && colors.orangeDark};
    background: ${(props) =>
      props.selected ? colors.orangeDark : "transparent"};
    border-radius: 50%;
    transition: all 0.5s;
  }
  &:hover {
    div {
      opacity: ${(props) => (props.selected ? "0" : "1")};
    }
  }
`

const OtherInputWrapper = styled.div`
  padding-top: 20px;
  @media (min-width: ${breakpoints.lg}) {
    padding: 20px 15px 15px 0;
  }
`
const OtherInput = styled.textarea`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 18px;
  line-height: 1.8;
  font-weight: 300;
  background: transparent;
  outline: none;
  border: none;
  width: 100%;
  border-bottom: 1px solid ${colors.grey};
  text-align: center;
  max-width: 100%;
  resize: none;
  height: 100px;

  @media (min-width: ${breakpoints.lg}) {
    text-align: start;
  }

  ::-webkit-input-placeholder {
    ${fonts.labGrotesqueLight};
    color: ${colors.grey};
  }
  ::-moz-placeholder {
    ${fonts.labGrotesqueLight};
    color: ${colors.grey};
  }
  :-ms-input-placeholder {
    ${fonts.labGrotesqueLight};
    color: ${colors.grey};
  }
  :-moz-placeholder {
    ${fonts.labGrotesqueLight};
    color: ${colors.grey};
  }
`

const AnswerHelperTextWrapper = styled.div`
  position: absolute;
  right: 18px;
  bottom: 34px;
  width: fit-content;
  height: fit-content;
  z-index: 7;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
  display: none;
  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }
`

const AnswerHelperText = styled.div`
  padding: 25px 35px 25px 35px;
  background: ${colors.peachMedium};
  border: 1px solid ${colors.grey};
  border-radius: 15px;
  border-bottom-right-radius: 0;
  ${fonts.labGrotesqueLight};
  font-size: 16px;
  line-height: 25px;
  text-transform: none;
  color: ${colors.grey};
  position: relative;
  text-align: left;
  min-width: 200px;
  max-width: 290px;
  @media (min-width: ${breakpoints.lg}) {
    min-width: 260px;
    max-width: 290px;
  }
  img {
    position: absolute;
    right: -1px;
    bottom: -13px;
    height: 14px;
  }
`

const QuestionHelperWrapper = styled.div`
  display: none;
  @media (min-width: ${breakpoints.lg}) {
    display: block;
    position: absolute;
    top: 50px;
    left: -79px;
    transform: translateX(-100%);
    margin: 0;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transition: opacity 0.4s ease-out;
  }
`

const QuestionHelper = styled.div`
  position: relative;
  padding: 35px 30px 42px 32px;
  width: 100%;
  background: ${colors.peach};
  border: 1px solid ${colors.grey};
  border-radius: 15px;
  border-bottom-right-radius: 0;
  ${fonts.labGrotesqueLight};
  font-size: 16px;
  line-height: 25px;
  text-transform: none;
  color: ${colors.grey};
  text-align: left;

  p {
    white-space: pre-wrap;
  }

  img {
    position: absolute;
    right: -1px;
    bottom: -20px;
    height: 20px;
    transform: rotate(90deg);
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 45px 40px 52px 42px;
    max-width: 415px;
    border-radius: 15px;
    border-top-right-radius: 0;
    img {
      right: -20px;
      top: -1px;
      height: 20px;
      transform: rotate(0deg);
    }
  }
`

const QuestionSlide = ({
  slide,
  questionSlides,
  increaseSlideIndex,
  decreaseSlideIndex,
  userSessionId,
  userAnswerList,
  setUserAnswerList,
  reachedQuestionStep,
  setReachedQuestionStep,
  currentSlideIndex,
  quizLength,
  containerRef
}) => {
  const [showHelp, setShowHelp] = useState(false)

  const [titleShowHelp, setTitleShowHelp] = useState(true)

  const [answers, setAnswers] = useState(() => {
    let initialAnswers = [...slide.answers]
    initialAnswers.forEach((answer) => {
      answer.isSelected = false
      userAnswerList.forEach((userAnswer) => {
        if (userAnswer.contentfulResponseId === answer.id) {
          answer.isSelected = true
          return
        }
      })
    })
    return initialAnswers
  })
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
  const [otherSelected, setOtherSelected] = useState(() => {
    if (otherOption && userAnswerList.length > 0) {
      const selectedAnswers = userAnswerList.filter(
        (answer) => answer.contentfulQuestionId === slide.id
      )
      let isSelectedOption = false
      selectedAnswers.forEach((selectedAnswer) => {
        if (selectedAnswer.contentfulResponseId === null) {
          isSelectedOption = true
        }
      })
      return isSelectedOption
    } else {
      return false
    }
  })
  const [otherValue, setOtherValue] = useState(() => {
    if (otherOption && userAnswerList.length > 0) {
      const selectedAnswers = userAnswerList.filter(
        (answer) => answer.contentfulQuestionId === slide.id
      )
      let selectedOptionValue = ""
      selectedAnswers.forEach((selectedAnswer) => {
        if (selectedAnswer.contentfulResponseId === null) {
          selectedOptionValue = selectedAnswer.answerLabel
        }
      })
      return selectedOptionValue
    } else {
      return ""
    }
  })

  let topRef = useRef(null)
  const [topHeight, setTopHeight] = useState(0)

  const inputRef = useRef(null)

  useEffect(() => {
    if (isBrowser()) {
      handleTopSize()
      window.addEventListener("resize", handleTopSize)
    }
    return window.removeEventListener("resize", handleTopSize)
  })

  const handleTopSize = () => {
    if (topRef.current) {
      setTopHeight(topRef.current.clientHeight)
    }
  }

  useEffect(() => {
    let initialAnswers = [...slide.answers]
    initialAnswers.forEach((answer) => {
      answer.isSelected = false
      userAnswerList.forEach((userAnswer) => {
        if (userAnswer.contentfulResponseId === answer.id) {
          answer.isSelected = true
          return
        }
      })
    })
    setAnswers(initialAnswers)

    if (otherOption && userAnswerList.length > 0) {
      const selectedAnswers = userAnswerList.filter(
        (answer) => answer.contentfulQuestionId === slide.id
      )
      let selectedOptionValue = ""
      let isSelectedOption = false
      selectedAnswers.forEach((selectedAnswer) => {
        if (selectedAnswer.contentfulResponseId === null) {
          selectedOptionValue = selectedAnswer.answerLabel
          isSelectedOption = true
        }
      })
      setOtherSelected(isSelectedOption)
      setOtherValue(selectedOptionValue)
    } else {
      setOtherSelected(false)
      setOtherValue("")
    }
  }, [currentSlideIndex])

  useEffect(() => {
    let disabled = true
    answers.forEach((answer) => {
      if (answer.isSelected) {
        disabled = false
        return
      }
    })

    if (otherSelected) {
      if (otherValue === "") {
        disabled = true
      } else {
        disabled = false
      }
    }
    setNextButtonDisabled(disabled)
  }, [answers, otherValue])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }, [otherSelected])

  let titleHelperRef = useRef(null)
  let titleRef = useRef(null)

  const handleTitleHelperText = (e) => {
    if (
      titleHelperRef.current &&
      !titleHelperRef.current.contains(e.target) &&
      titleRef.current &&
      !titleRef.current.contains(e.target)
    ) {
      setTitleShowHelp(false)
    }
  }

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener("mousemove", handleTitleHelperText)
    }
    return () => {
      window.removeEventListener("mousemove", handleTitleHelperText)
    }
  })

  const maxQuestions = questionSlides.length
  let currentQuestionNumber = 0
  questionSlides.forEach((question, index) => {
    if (question.id === slide.id) {
      currentQuestionNumber = index + 1
      return
    }
  })

  const remainingQuestions = maxQuestions - currentQuestionNumber

  const {
    question,
    direction,
    answersType,
    multipleSelections,
    id,
    otherOption,
    helperText,
  } = slide

  const clickOnAnswer = (answer) => {
    if (showHelp) {
      setShowHelp(false)
    }
    const updatedAnswers = [...answers]

    if (multipleSelections) {
      const currentAnswer = updatedAnswers.find(
        (currentAnswer) => currentAnswer.id === answer.id
      )
      currentAnswer.isSelected = !currentAnswer.isSelected
    } else {
      updatedAnswers.forEach((currentAnswer) => {
        if (currentAnswer.id === answer.id) {
          currentAnswer.isSelected = true
        } else {
          currentAnswer.isSelected = false
        }
      })
      if (otherOption && otherSelected) {
        setOtherSelected(false)
      }
    }
    setAnswers(updatedAnswers)
  }

  const handleOtherOption = () => {
    if (multipleSelections) {
      setOtherSelected(!otherSelected)
    } else {
      const updatedAnswers = [...answers]
      updatedAnswers.forEach((answer) => {
        answer.isSelected = false
      })
      setOtherSelected(!otherSelected)
      setAnswers(updatedAnswers)
    }
  }

  const getSelectedSkipTo = (selectedAnswers) => {
    let selectedSkipTo = null
    if (selectedAnswers.length > 0) {
      selectedSkipTo = selectedAnswers.reduce((prev, current) => {
        if (!current.skipToQuestionWeight) {
          current.skipToQuestionWeight = 0
        }
        return current.skipToQuestionWeight > prev.skipToQuestionWeight
          ? current
          : prev
      })
    }
    return selectedSkipTo?.skipToQuestion
  }

  const submitAnswers = async () => {
    const selectedAnswers = answers.filter(
      (answer) => answer.isSelected === true
    )

    const selectedSkipTo = getSelectedSkipTo(selectedAnswers)

    increaseSlideIndex(selectedSkipTo)

    if (reachedQuestionStep < currentQuestionNumber) {
      setReachedQuestionStep(currentQuestionNumber)
      const updatedUserAnswerList = [...userAnswerList]
      const currentAnswers = []

      selectedAnswers.forEach((selectedAnswer) => {
        const userAnswer = createAnswerObject(
          selectedAnswer.label,
          selectedAnswer.id,
          selectedAnswer.result?.slug
        )
        updatedUserAnswerList.push(userAnswer)
        currentAnswers.push(userAnswer)
      })

      if (otherOption && otherSelected) {
        const otherUserAnswer = createAnswerObject(otherValue)
        updatedUserAnswerList.push(otherUserAnswer)
        currentAnswers.push(otherUserAnswer)
      }
      setUserAnswerList(updatedUserAnswerList)
      await callQuizApi(currentAnswers)
    } else {
      const userAnswersForDelete = userAnswerList.filter(
        (userAnswer) => userAnswer.contentfulQuestionId === id
      )
      const remainingUserAnswers = userAnswerList.filter(
        (userAnswer) => userAnswer.contentfulQuestionId !== id
      )
      const currentAnswers = []
      selectedAnswers.forEach((selectedAnswer) => {
        const userAnswer = createAnswerObject(
          selectedAnswer.label,
          selectedAnswer.id,
          selectedAnswer.result?.slug
        )
        remainingUserAnswers.push(userAnswer)
        currentAnswers.push(userAnswer)
      })

      if (otherOption && otherSelected) {
        const otherUserAnswer = createAnswerObject(otherValue)
        remainingUserAnswers.push(otherUserAnswer)
        currentAnswers.push(otherUserAnswer)
      }
      setUserAnswerList(remainingUserAnswers)
      await callQuizApi(currentAnswers, userAnswersForDelete)
    }
    setOtherSelected(false)
    setOtherValue("")
  }

  const createAnswerObject = (label, responseId = null, result = null) => {
    return {
      stepNumber: currentQuestionNumber,
      contentfulQuestionId: id,
      contentfulResponseId: responseId,
      questionLabel: question,
      answerLabel: label,
      result: result,
    }
  }

  const callQuizApi = async (currentAnswers, answersForDelete = null) => {
    try {
      if (answersForDelete && answersForDelete.length > 0) {
        const deleteAnswerObj = {
          userSessionId: userSessionId,
          answers: answersForDelete,
        }
        const deletedAnswers = await deleteAnswers(deleteAnswerObj)
        // console.log("deletedAnswers", deletedAnswers)
      }

      const answerObj = {
        userSessionId: userSessionId,
        answers: currentAnswers,
      }
      const sentAnswers = await sendAnswers(answerObj)
      console.log("sentAnswers", sentAnswers)
    } catch (error) {
      console.error(error)
    }
  }

  const renderOtherInput = () => {
    return (
      <OtherInputWrapper>
        <OtherInput
          rows="4"
          area-label="Other"
          value={otherValue}
          onChange={(e) => setOtherValue(e.target.value)}
          ref={inputRef}
        />
      </OtherInputWrapper>
    )
  }

  return (
    <>
      <QuestionContainer>
        <Top ref={topRef}>
          <SlideInfo>
            <img
              src={ChevronLeft}
              alt="Left arrow"
              onClick={decreaseSlideIndex}
            />
            {`${
              remainingQuestions === 0
                ? "Last question"
                : `${remainingQuestions} questions remaining`
            } - ${quizLength} MIN`}
          </SlideInfo>
          {helperText && (
            <QuestionHelperWrapper
              visible={titleShowHelp ? 1 : 0}
              ref={titleHelperRef}
            >
              <QuestionHelper>
                {renderRichText(helperText)}
                <img src={TextBubbleTrianglePeach} alt="text bubble" />
              </QuestionHelper>
            </QuestionHelperWrapper>
          )}
          <Question
            onMouseOver={() => setTitleShowHelp(true)}
            onMouseLeave={() => setTitleShowHelp(false)}
            ref={titleRef}
          >
            {question}
          </Question>
          <Direction>{direction}</Direction>
        </Top>
        <AnswersContainer top={topHeight} ref={containerRef}>
          {answers && answersType === "buttons" && (
            <>
              <ButtonAnswers>
                {answers.map((answer) => {
                  const { isSelected, helperText, label, id } = answer
                  return (
                    <AnswerButton
                      key={id}
                      onClick={() => clickOnAnswer(answer)}
                      selected={isSelected ? 1 : 0}
                      helper={helperText}
                    >
                      {label}
                      {helperText && (
                        <AnswerHelperTextWrapper>
                          <AnswerHelperText>
                            {renderRichText(helperText)}
                            <img src={TextBubbleTriangle} alt="text bubble" />
                          </AnswerHelperText>
                        </AnswerHelperTextWrapper>
                      )}
                    </AnswerButton>
                  )
                })}
                {otherOption && (
                  <AnswerButton
                    onClick={handleOtherOption}
                    selected={otherSelected}
                  >
                    Other
                  </AnswerButton>
                )}
              </ButtonAnswers>
              {otherOption && otherSelected && renderOtherInput()}
            </>
          )}
          {answers && answersType === "radios" && (
            <>
              <RadioAnswers>
                {answers.map((answer) => {
                  const { id, isSelected, helperText, label } = answer
                  return (
                    <RadioButton
                      key={id}
                      onClick={() => clickOnAnswer(answer)}
                      selected={isSelected}
                      helper={helperText}
                    >
                      {label}
                      {helperText && (
                        <AnswerHelperTextWrapper>
                          <AnswerHelperText>
                            {renderRichText(helperText)}
                            <img src={TextBubbleTriangle} alt="text bubble" />
                          </AnswerHelperText>
                        </AnswerHelperTextWrapper>
                      )}
                    </RadioButton>
                  )
                })}
                {otherOption && (
                  <RadioButton
                    selected={otherSelected}
                    onClick={handleOtherOption}
                  >
                    Other
                  </RadioButton>
                )}
              </RadioAnswers>
              {otherOption && otherSelected && renderOtherInput()}
            </>
          )}
        </AnswersContainer>
      </QuestionContainer>
      <ButtonContainer>
        <ButtonWrapper desktop first>
          <OnClickButtonOrangeDark onClick={decreaseSlideIndex}>
            Back
          </OnClickButtonOrangeDark>
        </ButtonWrapper>
        <ButtonWrapper second disabled={nextButtonDisabled}>
          <OnClickButtonOrangeDark onClick={submitAnswers}>
            Next
          </OnClickButtonOrangeDark>
        </ButtonWrapper>
      </ButtonContainer>
    </>
  )
}

export default QuestionSlide
