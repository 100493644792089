import React, { useEffect, useRef, useState } from "react"
import styled, { css, keyframes } from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { BoldText, ButtonContainer, ButtonWrapper } from "./Styles"
import OnClickButtonOrangeDark from "../Core/Buttons/OnClickButtonOrangeDark"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import ChevronLeft from "../../resources/img/ui/dark/chevron-left.svg"

const ScrollAnimation = keyframes`
  0% {
    transform:translateY(50%);
  }
  100% {
    transform: translateY(-100%);
  }
`

const SlideInfo = styled(BoldText)`
  margin-bottom: 60px;
  position: relative;
  z-index: 1;
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 96px;
    margin-top: 10px;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 301px);
  overflow: scroll;
  position: relative;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: ${breakpoints.lg}) {
    max-width: 520px;
    /* height: calc( 100% - 114px ); */
    height: 429px;
  }
  @media (min-width: ${breakpoints.xl}) {
    max-width: 520px;
    /* height: calc( 100% - 114px ); */
    height: 459px;
  }
`

const Content = styled.div`
  animation: ${props => props.main && css`${ScrollAnimation} ${props.duration}s ease-out forwards`};
  position: ${props => !props.main && "absolute"};
  top: 0;
  padding: 60px 0;
  opacity: ${props => props.main ? 1 : props.visible? 1 : 0};
  transition: opacity 0.5s ease-in;
  p {
    ${fonts.canelaThin};
    font-size: 40px;
    line-height: 1;
    ${colors.grey};
    text-align: center;
    margin-bottom: 34px;
    &:last-of-type {
      margin-bottom: 0;
    }

    i {
      font-style: italic;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 130px 0;

    p {
      text-align: left;
    }
  }
`

const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(252, 247, 239, 1) 80%
  );
  height: 30%;
  width: 100%;
  ${(props) => (props.top ? "top: -1px" : "bottom: -1px")};
  transform: ${(props) => props.top && "rotate(180deg)"};
`

const OverlayContainer = styled.div`
  position: relative;
`

const BreakSlide = ({ slide, increaseSlideIndex, decreaseSlideIndex, currentSlideIndex }) => {
  const { content, heading } = slide
  let textRef = useRef(null)
  let wrapperRef = useRef(null)
  const [duration, setDuration] = useState(0)
  const [finished, setFinished] = useState(false)

  const getAnimationDuration = () => {
    const containerHeight = wrapperRef.clientHeight
    const textHeight = textRef.clientHeight
    let dur = Math.round(textHeight / containerHeight) * 15

    if (dur === 0) {
      dur = 20
    }

    setDuration(dur)
  }

  useEffect(() => {
    getAnimationDuration()
    window.addEventListener("resize", getAnimationDuration)
    return () => {
      window.removeEventListener("resize", getAnimationDuration)
    }
  })

  useEffect(() => {
    textRef.addEventListener("animationend", () => setFinished(true))
  })

  useEffect(() => {
    setFinished(false)
  }, [currentSlideIndex])

  return (
    <>
      <div>
        <SlideInfo>
          <img
            src={ChevronLeft}
            alt="Left arrow"
            onClick={() => decreaseSlideIndex()}
          />
          {heading}
        </SlideInfo>
        <OverlayContainer>
          <Overlay top />
          <ContentWrapper ref={(el) => (wrapperRef = el)}>
            <Content ref={(el) => (textRef = el)} duration={duration} main>
              {renderRichText(content)}
            </Content>
            <Content visible={finished ? 1 : 0}>
              {renderRichText(content)}
            </Content>
          </ContentWrapper>
          <Overlay />
        </OverlayContainer>
      </div>
      <ButtonContainer>
        <ButtonWrapper desktop first>
          <OnClickButtonOrangeDark onClick={() => decreaseSlideIndex()}>
            Back
          </OnClickButtonOrangeDark>
        </ButtonWrapper>
        <ButtonWrapper second>
          <OnClickButtonOrangeDark onClick={() => increaseSlideIndex()}>
            Next
          </OnClickButtonOrangeDark>
        </ButtonWrapper>
      </ButtonContainer>
    </>
  )
}

export default BreakSlide
