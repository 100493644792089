import React, { useEffect, useState } from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import { BoldText, ButtonContainer, ButtonWrapper, Heading, Paragraph } from "./Styles"
import OnClickButtonOrangeDark from "../Core/Buttons/OnClickButtonOrangeDark"
import ChevronLeft from "../../resources/img/ui/dark/chevron-left.svg"
import { RangeInput } from "./RangeInput"
import { deleteAnswers, sendAnswers } from "../../services/quiz/quiz"

const QuestionContainer = styled.div``
const Question = styled(Heading)`
  margin-bottom: 21px;
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 13px;
    max-width: 520px;
    text-align: left;
  }
`
const Direction = styled(Paragraph)`
  line-height: 1.8;
  margin-bottom: 24px;
  text-align: center;
  @media (min-width: ${breakpoints.lg}) {
    text-align: left;
  }
`
const SlideInfo = styled(BoldText)`
  margin-bottom: 119px;
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 16px;
    margin-top: 10px;
  }
`
const AnswersContainer = styled.div`
    @media (min-width: ${breakpoints.lg}) {
        /* overflow: auto; */
  }
`

const RangeContainer = styled.div`
    @media (min-width: ${breakpoints.lg}) {
        max-width: 535px;
    }
`;

const QuestionSlide = ({
  slide,
  questionSlides,
  increaseSlideIndex,
  decreaseSlideIndex,
  userSessionId,
  userAnswerList,
  setUserAnswerList,
  reachedQuestionStep,
  setReachedQuestionStep,
  currentSlideIndex,
  quizLength

}) => {
  const [selected, setSelected] = useState(() => {
    if(userAnswerList.length > 0) {
      const existingAnswer = userAnswerList.find(answer => answer.contentfulQuestionId === slide.id)
      if(existingAnswer) {
        return existingAnswer.answerLabel
      }
    }
    return "1"
  })

  useEffect(() => {
    if(userAnswerList.length > 0) {
      const existingAnswer = userAnswerList.find(answer => answer.contentfulQuestionId === slide.id)
      if(existingAnswer) {
        setSelected(existingAnswer.answerLabel)
        return
      }
    }
    setSelected("1")
  }, [currentSlideIndex])

  const maxQuestions = questionSlides.length
  let currentQuestionNumber = 0
  questionSlides.forEach((question, index) => {
    if (question.id === slide.id) {
      currentQuestionNumber = index + 1
      return
    }
  })

  const remainingQuestions = maxQuestions - currentQuestionNumber

  const { question, direction, id } = slide


  const submitAnswers = async () => {
    if(reachedQuestionStep < currentQuestionNumber) {

      setReachedQuestionStep(currentQuestionNumber)
      const updatedUserAnswerList = [...userAnswerList];

      const userAnswer = createAnswerObject(selected)
      updatedUserAnswerList.push(userAnswer)
      setUserAnswerList(updatedUserAnswerList);
      await callQuizApi([userAnswer])

    } else {
      const userAnswerForDelete = userAnswerList.find(userAnswer => userAnswer.contentfulQuestionId === id)
      const remainingUserAnswers = userAnswerList.filter(userAnswer => userAnswer.contentfulQuestionId !== id)

      const userAnswer = createAnswerObject(selected)
      remainingUserAnswers.push(userAnswer)
      setUserAnswerList(remainingUserAnswers);
      await callQuizApi([userAnswer], userAnswerForDelete)
    }

    increaseSlideIndex()
  }

  const createAnswerObject = (label) => {
    return {
      stepNumber: currentQuestionNumber,
      contentfulQuestionId: id,
      contentfulResponseId: null,
      questionLabel: question,
      answerLabel: label,
    }
  }
  const callQuizApi = async (currentAnswers, answersForDelete = null) => {
    try {
      if(answersForDelete && answersForDelete.length > 0) {
        const deleteAnswerObj = {
          userSessionId: userSessionId,
          answers: answersForDelete
        }
        const deletedAnswers = await deleteAnswers(deleteAnswerObj)
      }

      const answerObj = {
        userSessionId: userSessionId,
        answers: currentAnswers
      }
      const sentAnswers = await sendAnswers(answerObj);

      console.log(sentAnswers)

    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <QuestionContainer>
        <SlideInfo>
          <img
            src={ChevronLeft}
            alt="Left arrow"
            onClick={decreaseSlideIndex}
          />
          {`${
            remainingQuestions === 0
              ? "Last question"
              : `${remainingQuestions} questions remaining`
          } - ${quizLength} MIN`}
        </SlideInfo>
        <Question>{question}</Question>
        <Direction>{direction}</Direction>
        <AnswersContainer>
            <RangeContainer>
                <RangeInput max={slide.anwersRangeMaximum || 7} label={slide.title} setSelected={setSelected} selected={selected} />
            </RangeContainer>
        </AnswersContainer>
      </QuestionContainer>
      <ButtonContainer>
        <ButtonWrapper desktop first>
          <OnClickButtonOrangeDark onClick={decreaseSlideIndex}>
            Back
          </OnClickButtonOrangeDark>
        </ButtonWrapper>
        <ButtonWrapper second>
          <OnClickButtonOrangeDark onClick={submitAnswers}>
            Next
          </OnClickButtonOrangeDark>
        </ButtonWrapper>
      </ButtonContainer>
    </>
  )
}

export default QuestionSlide
