import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"

const Text = `
    ${fonts.labGrotesqueLight};
    font-size: 18px;
    font-style: normal;
    ${colors.grey};
`

export const JsonText = styled.div`
    p {
        ${Text}
    }
`
export const Paragraph = styled.p`
    ${Text}
`

export const Heading = styled.h1`
    ${fonts.canelaThin};
    font-size: 40px;
    line-height: 1;
    ${colors.grey};
    text-align: center;
`
export const BoldText = styled.h3`
    ${fonts.labGrotesqueBold}; 
    font-size: 12px;
    line-height: 2;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        width: 8px;
        height: auto;
        display: block;
        cursor: pointer;
    }
    @media (min-width: ${breakpoints.lg}) {
        display: block;
        img {
            display: none;
        }
    }
`

export const ButtonContainer = styled.div`
    padding: 32px 0px;
    border-top: 1px solid ${colors.grey};
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background: ${colors.ivory};
    z-index: 20;
    margin-top: 12px;
    @media (min-width: ${breakpoints.md}) {
        display: flex;
        bottom: 0;
        margin-top: 12px;
        width: 100%;
    }
    @media (min-width: ${breakpoints.lg}) {
        position: absolute;
        left: 50.5px;
        bottom: -43px;
        border: none;
        padding: 0 0 7px;
        display: flex;
        margin-top: 12px;
        height: unset;
        width: calc( 100% - 50.5px );
        padding: 0 0 68px ;
    }
`

export const ButtonWrapper = styled.div`
    display: ${props => props.desktop && "none"};
    button {
        width: 100%;
        background: ${props => props.disabled && "rgba(189, 146, 123, 0.4)"};
        border-color: ${props => props.disabled && "rgba(189, 146, 123, 0.4)"};
        pointer-events: ${props => props.disabled && "none"};
        cursor: ${props => props.disabled && "normal"};
    }
    @media (min-width: ${breakpoints.md}) {
        display: ${props => props.desktop && "block"};
        flex: 1;
        margin-right: ${props => props.first && "9.5px"};
        margin-left: ${props => props.second && "9.5px"};
    }
    @media (min-width: ${breakpoints.lg}) {
        max-width: 258px;
    }
`
