import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"

const InputWrapper = styled.div`
  width: 100%;
  padding: 54px 36px;
  border-radius: 15px;
  background: ${colors.white};
  position: relative;

  span {
    display: block;
    border: none;
    height: 36px;
    width: 36px;
    border-radius: 0;
    position: absolute;
    top: 50%;
    margin: 0 38px 0;
    font-size: 36px;
    line-height: 1;
    text-align: center;
    background: transparent;
    pointer-events: none;
    transform: translateY(-50%);
    left: 0;
  }

  input {
    width: 100%;
  }

  //Override basic appearance
  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    background: ${colors.peachMedium};
    border-radius: 100px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  // Styling the Thumb

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 36px;
    width: 36px;
    cursor: pointer;
    background-color: transparent;
    margin-top: -11px;
  }

  input[type="range"]::-moz-range-thumb {
    border: none;
    height: 36px;
    width: 36px;
    background-color: transparent;
    cursor: pointer;
  }

  input[type="range"]::-ms-thumb {
    border: none;
    height: 36px;
    width: 36px;
    cursor: pointer;
    background-color: transparent;
  }

  // Styling the Track
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 13px;
    cursor: pointer;
    background: transparent;
    border-radius: 100px;
    border: none;
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: transparent;
  }

  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 13px;
    cursor: pointer;
    background: ${colors.peachMedium};
    border-radius: 100px;
    border: none;
  }

  input[type="range"]::-ms-track {
    width: 100%;
    height: 13px;
    cursor: pointer;
    background: ${colors.peachMedium};
    border: none;
    color: transparent;
    border-radius: 100px;
  }
  input[type="range"]::-ms-fill-lower {
    background: ${colors.orangeDark};
    border: none;
    border-radius: 100px;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: ${colors.orangeDark};
  }
  input[type="range"]::-ms-fill-upper {
    background: ${colors.peachMedium};
    border: none;
    border-radius: 100px;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: ${colors.peachMedium};
  }
`

const Numbers = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 36px 17px;
  justify-content: space-between;
`
const Number = styled.div`
  text-align: center;
  ${fonts.labGrotesqueBold};
  font-size: 12px;
  line-height: 2;
  letter-spacing: 2.4px;
  color: ${colors.grey};
  padding-left: 6px;
  width: 36px;
  opacity: ${(props) => (props.selected ? "1" : "0")};
`

const FlowEmoji = styled.div`
  position: absolute;
  bottom: ${props => props.complete ? `calc(100% + ${props.scale * 30}px)` : `calc(100% + ${(props.scale / 2) * 20}px)`};
  transition: opacity 1s ease;
  transition: bottom 1s ease;
  top: unset;
  transform: ${(props) => `scale(1.${props.scale - 1})`};
  z-index: 1;
  opacity: ${props => props.visible ? "1" : "0"};
`

const emojis = ["🙃", "😐", "🙁", "😣", "😫", "😠", "😡"]

export const RangeInput = ({ max, label, setSelected, selected }) => {
  const [value, setValue] = useState(selected)
  const [flowEmojiVisible, setFlowEmojiVisible] = useState(false)
  const [flowEmojiComplete, setFlowEmojiComplete] = useState(false)
  const [emoji, setEmoji] = useState(() => {
    let emojiValue = "🙃"
    emojis.forEach((currectEmoji, index) => {
      if (index + 1 == selected) {
        emojiValue = currectEmoji
      }
    })
    return emojiValue
  })

  const min = 1
  const sliderRef = useRef(null)
  const emojiRef = useRef(null)
  const inputRef = useRef(null)

  useEffect(() => {
    setSelected(value)
    if (inputRef.current) {
      const gradient = `linear-gradient(to right, ${colors.orangeDark} 0%, ${
        colors.orangeDark
      } ${((value - min) / (max - min)) * 100}%, ${colors.peachMedium} ${
        ((value - min) / (max - min)) * 100
      }%, ${colors.peachMedium} 100%)`
      inputRef.current.style.backgroundImage = gradient
    }
    if (sliderRef.current && emojiRef.current) {
      const gradient = `linear-gradient(to right, ${colors.orangeDark} 0%, ${
        colors.orangeDark
      } ${((value - min) / (max - min)) * 100}%, ${colors.peachMedium} ${
        ((value - min) / (max - min)) * 100
      }%, ${colors.peachMedium} 100%)`
      const elementWidth = sliderRef.current.clientWidth
      const correction = (emojiRef.current.clientWidth / max) * (value - 1)
      const position = (elementWidth / max) * (value - 1) - correction
      emojiRef.current.style.left = `${position}px`
    }

    let emojiValue = "🙃"
    emojis.forEach((currectEmoji, index) => {
      if (index + 1 == value) {
        emojiValue = currectEmoji
      }
    })
    setEmoji(emojiValue)
  }, [value])

  let numbers = []
  for (let i = 1; i < max + 1; i++) {
    numbers.push(i)
  }

  // const gradient = `linear-gradient(to right, ${colors.orangeDark} 0%, ${colors.orangeDark} ${(value-min)/(max-min)*100}%, ${colors.peachMedium} ${(value-min)/(max-min)*100}%, ${colors.peachMedium} 100%)`

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const handleFlowEmoji = () => {
    setFlowEmojiComplete(true)
    const visibleEmoji = setTimeout(() => {
      setFlowEmojiVisible(false)
      clearTimeout(visibleEmoji)
    }, 1000);
    const flowComeplete = setTimeout(() => {
      setFlowEmojiComplete(false)
      clearTimeout(flowComeplete)
    }, 2000);
  }

  return (
    <InputWrapper
      ref={sliderRef}
      selected={value}
      happy={value === "1"}
      angry={value === max.toString()}
    >
      <span ref={emojiRef}>
        <FlowEmoji scale={value} visible={flowEmojiVisible ? 1 : 0} complete={flowEmojiComplete ? 1 : 0}>{emoji}</FlowEmoji>
        {emoji}
      </span>
      <input
        ref={inputRef}
        type="range"
        name="numbers"
        step="1"
        min="1"
        max={max.toString()}
        value={value}
        onChange={(e) => handleChange(e)}
        area-label={label}
        onTouchStart={() => setFlowEmojiVisible(true)}
        onTouchEnd={() => handleFlowEmoji()}
        onMouseDown={() => setFlowEmojiVisible(true)}
        onMouseUp={() => handleFlowEmoji()}
      />
      <Numbers>
        {numbers.map((number, index) => (
          <Number key={index} selected={number === parseInt(value)}>
            {number}
          </Number>
        ))}
      </Numbers>
    </InputWrapper>
  )
}
