import quizProvider from "../api/quiz/quiz"

export const sendEmailData = async (userSessionId, swellUserId, email, marketingOptIn) => {
    const data = {userSessionId, swellUserId, email, marketingOptIn}
    return await quizProvider.sendEmailData(data)
}

export const sendAnswers = async(answers) => {
    return await quizProvider.sendAnswers(answers);
}

export const deleteAnswers = async(answers) => {
    return await quizProvider.deleteAnswers(answers);
}
