import axios from "axios"

export const ENDPOINT = process.env.API_URL

const client = axios.create({
  baseURL: ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
  },
})

client.interceptors.response.use(
  function(res) {
    return res
  },
  function(error) {
    return Promise.reject(error)
  },
)

export default client
