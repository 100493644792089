import api from "../core/api"

const BASE_PATH = '/quiz';

async function sendEmailData(data) {
    return await api.post(BASE_PATH + '/email', data);
}

async function sendAnswers(data) {
    return await api.post(BASE_PATH + "/answer", data)
}

async function deleteAnswers(data) {
    return await api.post(BASE_PATH + "/delete-answers", data)
}


const quiz = {
    sendEmailData,
    sendAnswers,
    deleteAnswers
};

export default quiz;