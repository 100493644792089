import React, { useRef, useState } from "react"
import QuizIntro from "./QuizIntro"
import BreakSlide from "./BreakSlide"
import QuestionSlide from "./QuestionSlide"
import QuizContact from "./QuizContact"
import RangeQuestionSlide from "./RangeQuestionSlide"
import { isBrowser } from "../../services/browser"

const SlideContents = ({ slides, questionSlides, quizLength }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const [userSessionId, setUserSessionId] = useState(null)
  const [userAnswerList, setUserAnswerList] = useState([])
  const [reachedQuestionStep, setReachedQuestionStep] = useState(0)
  const [quizFlow, setQuizFlow] = useState([])
  let answerContainerRef = useRef(null)

  const scrollToTop = () => {
    if (isBrowser()) {
      window.scrollTo(0, 0)
      if (answerContainerRef.current) {
        answerContainerRef.current.scrollTo(0, 0)
      }
    }
  }

  const increaseSlideIndex = (skipTo) => {
    scrollToTop()
    let newQuizFlow = [...quizFlow]
    let newCurrentSlideIndex = currentSlideIndex + 1

    if (skipTo) {
      let skipToQuestion = slides.find((slide) => slide.questionId === skipTo)

      if (skipToQuestion) {
        newCurrentSlideIndex = skipToQuestion.index
      }
    }

    newQuizFlow.push(slides[currentSlideIndex])
    setQuizFlow(newQuizFlow)
    setCurrentSlideIndex(newCurrentSlideIndex)
  }

  const decreaseSlideIndex = () => {
    scrollToTop()
    let newQuizFlow = [...quizFlow]
    let newCurrentSlide = newQuizFlow.pop()
    const newCurrentSlideIndex = newCurrentSlide.index
    setQuizFlow(newQuizFlow)
    setCurrentSlideIndex(newCurrentSlideIndex)
  }

  const renderContent = () => {
    const slide = slides[currentSlideIndex]
    const type = slide.__typename

    if (currentSlideIndex === 0) {
      return (
        <QuizIntro
          slide={slide}
          increaseSlideIndex={increaseSlideIndex}
          setUserSessionId={setUserSessionId}
        />
      )
    }
    if (currentSlideIndex === slides.length - 1) {
      return (
        <QuizContact
          slide={slide}
          decreaseSlideIndex={decreaseSlideIndex}
          userAnswerList={userAnswerList}
        />
      )
    }

    if (type === "ContentfulQuizTextBlock") {
      return (
        <BreakSlide
          slide={slide}
          increaseSlideIndex={increaseSlideIndex}
          decreaseSlideIndex={decreaseSlideIndex}
          currentSlideIndex={currentSlideIndex}
        />
      )
    }
    if (slide.answersType === "range") {
      return (
        <RangeQuestionSlide
          slide={slide}
          increaseSlideIndex={increaseSlideIndex}
          decreaseSlideIndex={decreaseSlideIndex}
          questionSlides={questionSlides}
          userAnswerList={userAnswerList}
          userSessionId={userSessionId}
          reachedQuestionStep={reachedQuestionStep}
          setReachedQuestionStep={setReachedQuestionStep}
          setUserAnswerList={setUserAnswerList}
          quizLength={quizLength}
        />
      )
    }
    return (
      <QuestionSlide
        slide={slide}
        increaseSlideIndex={increaseSlideIndex}
        decreaseSlideIndex={decreaseSlideIndex}
        questionSlides={questionSlides}
        userAnswerList={userAnswerList}
        userSessionId={userSessionId}
        reachedQuestionStep={reachedQuestionStep}
        setReachedQuestionStep={setReachedQuestionStep}
        setUserAnswerList={setUserAnswerList}
        currentSlideIndex={currentSlideIndex}
        quizLength={quizLength}
        containerRef={answerContainerRef}
      />
    )
  }

  return <>{renderContent()}</>
}

export default SlideContents
