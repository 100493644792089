import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Quiz from "../components/Quiz/Quiz"

const QuizPage = ({location, data}) => {
    const path = location.pathname
    const { page } = data

    return (
        <Layout>
          <Seo
            path={path}
            title={page.metaTitle}
            description={page.metaDescription}
          />
          <Quiz block={page.blocks[0]}/>
        </Layout>
      )
}

export default QuizPage

export const pageQuery = graphql`
  query QuizPageQuery {
    page: contentfulPage(slug: { eq: "quiz" }) {
      id
      metaTitle
      metaDescription
      blocks {
        __typename
        ... on ContentfulQuiz {
          topImageText
          bottomImageText
          quizLength
          media {
            title
            gatsbyImageData(width: 1356)
            file {
              url
              contentType
            }
          }
          introSlide {
            id
            heading
            title
            subtitle
            ctaLabel
            content {
              raw
            }
          }
          endingSlide {
            id
            heading
            title
            ctaLabel
            content {
              raw
            }
          }
          slides {
            __typename
            ... on ContentfulQuizTextBlock {
              id
              heading
              content {
                raw
              }
            }
            ... on ContentfulQuizQuestion {
              id
              title
              question
              direction
              answers {
                id
                label
                skipToQuestion
                skipToQuestionWeight
                helperText {
                  raw
                }
                result {
                  slug
                }
              }
              answerRangeMaximum
              answersType
              multipleSelections
              otherOption
              helperText {
                raw
              }
              questionId
            }
          }
        }
      }
    }
  }
`

